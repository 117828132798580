import * as React from 'react';

import Arch from '../assets/images/arch.svg';
import Arrive from '../assets/images/arrive.svg';
import Bed from '../assets/images/bed.svg';
import Beer from '../assets/images/beer.svg';
import Camera from '../assets/images/camera.svg';
import Cake from '../assets/images/cake.svg';
import Champagne from '../assets/images/champagne.svg';
import Confetti from '../assets/images/confetti.svg';
import Dinner from '../assets/images/dinner.svg';
import Rings from '../assets/images/rings.svg';

import './timeline.css';

const schedule = [
  {
    name: 'Arrival',
    time: '2:00PM',
    Icon: Arch,
    description:
      'Guests staying at the hotels can check into their hotel rooms. The Wingfield Suite also opens for guests to relax in before the ceremony starts. All guests must be at The White Hart Inn by 2:30pm.',
  },
  {
    name: 'Ceremony',
    time: '3:00PM',
    Icon: Rings,
    description: 'The ceremony begins.',
  },
  {
    name: 'Drinks',
    time: '3:30PM',
    Icon: Champagne,
    description:
      'The ceremony ends. Guests enter Wingfield Suite for reception drinks and snacks. Couple departs for photos shortly after.',
  },
  {
    name: 'Photos',
    time: '4:15PM',
    Icon: Camera,
    description:
      'Photos taken with friends and family. Enter main reception room afterwards.',
  },
  {
    name: 'Late Lunch',
    time: '5:00PM',
    Icon: Dinner,
    description: 'Two course meal is served.',
  },
  {
    name: 'Party',
    time: '6:20PM',
    Icon: Confetti,
    description: 'Enjoy the games, axe throwing, bouncy castle and photobooth.',
  },
  {
    name: 'Evening Arrivals',
    time: '6:30PM',
    Icon: Arrive,
    description: 'Evening guests join the party.',
  },
  {
    name: 'Cake',
    time: '7:30PM',
    Icon: Cake,
    description: 'The mini cake is cut. Tuck in to some treats.',
  },
  {
    name: 'Dinner',
    time: '9:00PM',
    Icon: Dinner,
    description:
      'Guests help themselves to their wood-fired pizza in the outdoor terrace (weather permitting).',
  },
  {
    name: 'Final Orders',
    time: '12:15AM',
    Icon: Beer,
    description: 'Grab a final drink before the bartender leaves.',
  },
  {
    name: 'Retire',
    time: '12:30AM',
    Icon: Bed,
    description: "Time to head to bed, it's been a long day!",
  },
];

const Timeline = () => {
  return (
    <div className="ListTimeline">
      {schedule.map(({ name, time, Icon, description }) => (
        <>
          <Icon className="ItemIcon" />
          <div className="ListItem-Text">
            <h2>
              {time.toLocaleLowerCase()}: {name}
            </h2>
            <p className="ListItemContent">{description}</p>
          </div>
        </>
      ))}
    </div>
  );
};

export default Timeline;
