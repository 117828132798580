import * as React from 'react';

import Layout from '../components/layout';
import Seo from '../components/seo';
import Timeline from '../components/timeline';
import './index.css';
import './schedule.css';

const SchedulePage = () => {
  return (
    <Layout>
      <Seo title="Schedule" />
      <div className="content">
        <h1>The Schedule</h1>
        <p className="ScheduleIntro" style={{ marginBottom: '1rem' }}>
          Here is the schedule we're hoping to keep to on the day.
          <br />
          Obviously the more we drink, the further from it we'll probably drift.
          <br />
          But oh well - it's nice to think we can keep to it.
        </p>
        <Timeline />
      </div>
    </Layout>
  );
};

export default SchedulePage;
